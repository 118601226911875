// import React from 'react'
// import './Footer.css'

// function Footer() {
//   return (
//     <div className='footer__container'>
//         <div className="term__of__service">
//             <a href="https://www.privacypolicies.com/live/315f063d-1a54-4050-b229-eb4bde71186f">
//                 Privacy policy
//             </a>
//             <a href="">
//                 About us
//             </a>
//         </div>

//         <div className="download__icons__container">
//             <a href="https://apps.apple.com/gh/app/asasefie/id1666692315">
//                 <img
//                     src='https://s.zillowstatic.com/pfs/static/app-store-badge.svg'
//                 />
//             </a>

//             <a href="https://play.google.com/store/apps/details?id=com.asasefie.realestate.app&hl=en&gl=US">
//                 <img
//                     src='https://s.zillowstatic.com/pfs/static/google-play-badge.svg'
//                 />
//             </a>
//         </div>

//         <div className="copyright">
//             <p>African property search</p>
//             <h3>© 2023 Asasefie</h3>
//         </div>
//     </div>
//   )
// }

// export default Footer

import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <>
        <div className='container__footer'>


            <div className="first__section__container">
                {firstSecttionData.map((item, index) => {
                    return(
                        <a className='links' style={{ textDecoration: 'none' }} href={item.to}>
                            <p >{item.name}</p>
                        </a>
                    )
                })}
            </div>

            <div className="second__section__container">

                <div className="text__footer">
                    <p className='text__first'>Asasefie dedicated to helping you find your dream home or sell your property quickly and for the best price possible. Our experienced team of real estate agents is here to assist you with all of your needs, whether you're buying, selling, or just browsing. Contact us today to get started on your real estate journey!</p>
                    <p className='contact__us'>Contact Us</p>
                </div>

                <div className="mobile__app">
                    <a href="https://apps.apple.com/gh/app/asasefie/id1666692315">
                        <img
                            className='download__image'
                            src='https://s.zillowstatic.com/pfs/static/app-store-badge.svg'
                        />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.asasefie.realestate.app&hl=en&gl=US">
                        <img
                            className='download__image'
                            src='https://s.zillowstatic.com/pfs/static/google-play-badge.svg'
                        />
                    </a>
                </div>

                <div className="social">
                    <img src={require('../../../assets/logo.png')} alt="" className='logo__footer'/>
                    <p>Follow us: </p>
                    <a href="https://www.instagram.com/asasefie/"><img className="social__logo" src="https://www.svgrepo.com/show/512399/instagram-167.svg" alt="" /></a>
                    <a href="https://twitter.com/asasefie"><img className="social__logo" src="https://www.svgrepo.com/show/509274/twitter.svg" alt="" /></a>
                    <a href="https://www.facebook.com/profile.php?id=100089688347044"><img className="social__logo" src="https://www.svgrepo.com/show/509923/facebook.svg" alt="" /></a>
                    <a href="https://www.tiktok.com/@asasefie?_t=8bXpdM6eNCX&_r=1"><img className="social__logo" src="https://www.svgrepo.com/show/463367/tiktok.svg" alt="" /></a>
                    <a href="https://www.youtube.com/@asasefie"><img className="social__logo" src="https://www.svgrepo.com/show/509293/youtube.svg" alt="" /></a>
                    <p>© 2023 Asasefie</p>
                </div>

                <div className="houses">
                    <img className="houses__image" src="https://www.svgrepo.com/show/124324/city-buildings.svg" alt="" />
                    <img className="grass__image" src="https://www.svgrepo.com/show/504030/grass-leaf-plant-4.svg" alt="" />
                    <img className="houses__image" src="https://www.svgrepo.com/show/175162/buildings-modern-couple.svg" alt="" />
                    <img className="grass__image" src="https://www.svgrepo.com/show/504020/grass-leaf-plant-14.svg" alt="" />
                    <img className="houses__image" src="https://www.svgrepo.com/show/168884/two-buildings-with-many-windows.svg" alt="" />
                    <img className="houses__image" src="https://www.svgrepo.com/show/81387/offices-buildings.svg" alt="" />
                    <img className="grass__image" src="https://www.svgrepo.com/show/503370/grass.svg" alt="" />
                    <img className="houses__image" src="https://www.svgrepo.com/show/80857/buildings.svg" alt="" />
                    <img className="houses__image" src="https://www.svgrepo.com/show/106582/two-city-buildings.svg" alt="" />
                </div>
            </div>


        </div>
    </>
  )
}

export default Footer

const firstSecttionData = [
    {
        name: 'About',
        to: ''
    },
    {
        name: 'Help',
        to: ''
    },
    {
        name: 'Advertise',
        to: ''
    },
    {
        name: 'Terms of use',
        to: 'https://www.privacypolicies.com/live/315f063d-1a54-4050-b229-eb4bde71186f'
    },
    {
        name: 'Privacy policy',
        to: 'https://www.privacypolicies.com/live/315f063d-1a54-4050-b229-eb4bde71186f'
    },
    {
        name: 'Blog',
        to: ''
    },
    {
        name: 'Mobile apps',
        to: ''
    },
    {
        name: 'Virtual Tours',
        to: ''
    },
]