import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Footer from "../footer/Footer";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#0063cc",
  borderColor: "#0063cc",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[500],
  "&:hover": {
    backgroundColor: grey[700],
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://asasefie.com/">
        Asasefie
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Auth() {
  const [signUpBool, setSignUpBool] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useNavigate();
  const { signup } = useAuth();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    if (signUpBool) {
      userSignUp(email, password);
    } else {
      userLogIn(email, password);
    }
  };

  const userSignUp = async (email, password) => {
    setLoading(true);
    try {
      await signup(email, password)
        .then(async (result) => {
          try {
            const apiResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/createuserprofile`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                }),
              }
            );
            const data2 = await apiResponse.json();
            // console.log("data", data2.data);

            sendAsasefieMessage(email);

            firebase
              .firestore()
              .collection("users")
              .doc(firebase.auth().currentUser.uid)
              .set({
                email: email,
                _id: data2.data._id,
                isAgent: false,
              });
          } catch (error) {
            console.log(error);
          }

          history("/");
          // console.log(result);
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
        });
    } catch (error) {
      alert("Error :", error);
      setLoading(false);
    }
  };

  const sendAsasefieMessage = async (email) => {
    const body = {
      firstUserEmail: "Asasefie",
      secondUserEmail: email,
      firstUserMessage:
        "Thank you for downloading our app! We hope you enjoy using it and find it useful. If you have any feedback or suggestions, we'd love to hear from you. ",
      secondUserMessage: "",
    };
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/sendmessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data2 = await apiResponse.json();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const userLogIn = async (email, password) => {
    // console.log(emailRef.current.value)
    setLoading(true);
    try {
      await login(email, password)
        .then((result) => {
          history("/");
          // console.log(result)
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert("Error :", error);
    }
    setLoading(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "calc(100vh - 80px)", zIndex: -1 }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={1}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "black" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {signUpBool ? "Sign up" : "Sign in"}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  color="primary"
                  sx={{ color: "white", borderColor: "black" }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  color="primary"
                  sx={{ color: "white", borderColor: "black" }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <ColorButton
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "black",
                    color: "white",
                  }}
                  disabled={loading}
                >
                  {signUpBool ? "Sign Up" : "Sign In"}
                </ColorButton>
                {/* <Button
                  type="submit"
                  fullWidth
                  variant='contained'
                  color='secondary'
                  sx={{ mt: 3, mb: 2, backgroundColor: 'black', color: 'white',  }}
                >
                  {signUpBool? ('Sign Up'):('Sign In')}
                </Button> */}
                <Grid container>
                  <Grid item xs sx={{ textAlign: "left" }}>
                    {!signUpBool ? (
                      <Link href="#" variant="body2" sx={{ color: "black" }}>
                        Forgot password?
                      </Link>
                    ) : null}
                  </Grid>
                  <Grid item sx={{ textAlign: "right" }}>
                    <Link
                      onClick={() => setSignUpBool(!signUpBool)}
                      href="#"
                      variant="body2"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      {signUpBool
                        ? "Already have an account? Sign In"
                        : "Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Footer />
    </>
  );
}
