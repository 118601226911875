// import React, {useEffect, useState, useMemo, useRef} from 'react'
// import { GoogleMap, useJsApiLoader, Marker, InfoBox } from '@react-google-maps/api';

// import Card from '../../common/card/Card'
// import './MapComponent.css'
// import { BeatLoader } from 'react-spinners';

// const pointInPolygon = require('point-in-polygon');

// const containerStyle = {
//     width: '65%',
//     height: '90vh'
//   };

// //   const center = {
// //     lat: -3.745,
// //     lng: -38.523
// //   };

//   const createMapOptions = {
//     zoomControl: true,
//     mapTypeControl: true,
//     scaleControl: false,
//     streetViewControl: false,
//     rotateControl: true,
//     fullscreenControl: false,
//   }

// function MapComponent(props) {
//     const [turfBoundaries, setTurfBoundaries] = useState([]);
//     const [mapBoundaries, setMapBoundaries] = useState([]);
//     const [housesFromDb, setHousesFromDb] = useState([]);
//     const [housesToRender, setHouseToRender] = useState([]);
//     const [loadingHouses, setLoadingHouses] = useState(true);
//     const [zoomedFar, setZoomedFar] = useState(false);
//     const [selectedMarker, setSelectedMarker] = useState(null);
//     const [selectedMarkerPosition, setSelectedMarkerPosition] = useState(null);

//     const mapRef = useRef(null);

//     const { isLoaded } = useJsApiLoader({
//       id: 'google-map-script',
//       googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
//     });

//     const [map, setMap] = React.useState(null);

//     useEffect(() => {
//       // onLoad()
//     },[props])

//     const onLoad = React.useCallback(function callback(map) {
//       mapRef.current = map;
//       const bounds = new window.google.maps.LatLngBounds(center);
//       map.fitBounds(bounds);
//       console.log('bounds', bounds)

//       setMap(map);
//       onDragEnd();
//     }, []);

//     const onUnmount = React.useCallback(function callback(map) {
//       setMap(null);
//     }, []);

//     const onDragEnd = async () => {
//       const map = mapRef.current;
//       let zoomLevel = map.getZoom();
//       if (zoomLevel < 14) {
//         setZoomedFar(true);
//       } else {
//         setZoomedFar(false);
//       }

//       const bounds = map.getBounds();

//       const ne = bounds.getNorthEast();
//       const sw = bounds.getSouthWest();

//       const nw = new window.google.maps.LatLng(ne.lat(), sw.lng());
//       const se = new window.google.maps.LatLng(sw.lat(), ne.lng());

//       const polygonCoords = [
//         [ne.lat(), ne.lng()],
//         [nw.lat(), nw.lng()],
//         [sw.lat(), sw.lng()],
//         [se.lat(), se.lng()],
//         [ne.lat(), ne.lng()],
//       ];

//       const polygonCoords2 = [
//         [ne.lng(), ne.lat()],
//         [nw.lng(), nw.lat()],
//         [sw.lng(), sw.lat()],
//         [se.lng(), se.lat()],
//         [ne.lng(), ne.lat()],
//       ];

//       setTurfBoundaries(polygonCoords);
//       setMapBoundaries(polygonCoords2);

//       if (mapBoundaries.length !== 0) {
//         fetchHousesWithinBoundary();
//       }
//     };

//     const fetchHousesWithinBoundary = async () => {
//       setLoadingHouses(true)
//       const data = mapBoundaries;
//       try {
//         const apiResponse = await fetch(
//           `${process.env.REACT_APP_API_URL}/houseswithinboundary`,
//           {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data),
//           }
//         );
//         const data2 = await apiResponse.json();
//         // console.log('data from api', data2.data);
//         setHousesFromDb(data2.data);
//         // console.log(housesFromDb);
//         if (data2.data.length !== 0) {
//           housesFilter(data2.data);
//           // setHouseToRender([]);
//         }
//         else {
//           setLoadingHouses(false)
//         }
//       } catch (error) {
//         setLoadingHouses(false)
//         console.log(error);
//       }
//     };

//     const housesFilter = (item) => {
//         const houses2 = item.map((item, index) => {
//           let pt = [item.houseCoordinates.coordinates[1],item.houseCoordinates.coordinates[0]];
//           let poly = turfBoundaries;

//           if(pointInPolygon(pt, poly)){
//             let data = item
//             return data
//           }
//         }).flat(1)

//         // console.log("houses",houses2)

//         const removeUndefined = houses2.filter(( element ) => {
//           return element !== undefined ;
//         })
//         // console.log(removeUndefined)
//         // this.setState({ housesToRender: removeUndefined, loadingHouses: false })
//         setHouseToRender(removeUndefined)
//         // this.setState({ showMarkers: true })
//         setLoadingHouses(false)
//       }

//       const onChildClick = (marker) => {
//         setSelectedMarker(marker);
//         setSelectedMarkerPosition({ lat: marker.houseCoordinates.coordinates[1],lng: marker.houseCoordinates.coordinates[0] });
//       };

//       const center = useMemo(() => {
//         return { lat: props.center.lat, lng: props.center.lng }; // replace with your desired initial center coordinates
//       }, []);

//     return isLoaded ? (
//         <div style={{ display: 'flex', flexDirection: 'row' }}>
//             <GoogleMap
//                 id="map"
//                 mapContainerStyle={containerStyle}
//                 center={center}
//                 zoom={16}
//                 onLoad={onLoad}
//                 onUnmount={onUnmount}
//                 onDragEnd={onDragEnd}
//                 onZoomChanged={onDragEnd}
//                 options={createMapOptions}
//             >
//                 {zoomedFar? (
//                     <div style={{ position: 'absolute', top: 0 , right: 0, padding: 10, backgroundColor: 'white', borderRadius: 5}}>
//                         <p>Zomed out too far</p>
//                     </div>
//                 ):(null)}

//                 {housesToRender.map((marker) => {
//                     console.log('marker', marker)
//                     return(
//                     <Marker
//                     // icon={{
//                     //     url: require('../../images/marker.png'),
//                     //     scaledSize: {
//                     //       width: 50,
//                     //       height: 50
//                     //     }
//                     // }}

//                     key={marker._id}
//                     position={{lat: marker.houseCoordinates.coordinates[1],lng: marker.houseCoordinates.coordinates[0]}}
//                     onClick={() => onChildClick(marker)}
//                     />
//                 )})}

//                 {selectedMarker && selectedMarkerPosition && (
//                     <InfoBox
//                     position={selectedMarkerPosition}
//                     options={{ closeBoxURL: '', enableEventPropagation: true }}
//                     >
//                     <div style={{ backgroundColor: 'white', padding: '7px' }}>
//                         <img src={selectedMarker.houseImage[0]} />
//                         <h2>$ {selectedMarker.price}</h2>
//                         <h4>{selectedMarker.country}</h4>
//                         <p>{selectedMarker.description}</p>
//                     </div>
//                     </InfoBox>
//                 )}

//             </GoogleMap>
//             <div className="house__result">
//                 {/* <RecentCard houses={housesToRender} /> */}
//                 {loadingHouses? (
//                   <div className="loading">
//                     <BeatLoader
//                       size={50}
//                       color={'black'}
//                     />
//                   </div>
//                 ):(
//                   <div>
//                     {housesToRender.map((item, index) => {
//                       return(
//                         <div className="card__parent__div">
//                           <Card item={item} />
//                         </div>
//                       )
//                     })}
//                   </div>
//                 )}
//             </div>
//         </div>
//     ) : <></>
// }

// export default MapComponent

// import React, { useEffect, useState, useRef, useMemo } from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const pointInPolygon = require('point-in-polygon');

// const containerStyle = {
//   width: '50vw',
//   height: '88vh'
// };

// const createMapOptions = {
//   zoomControl: true,
//   mapTypeControl: true,
//   scaleControl: false,
//   streetViewControl: false,
//   rotateControl: true,
//   fullscreenControl: false,
// }

// function MapComponent({coords}) {
//   // const [turfBoundaries, setTurfBoundaries] = useState([]);
//   // const [mapBoundaries, setMapBoundaries] = useState([]);
//   const [housesFromDb, setHousesFromDb] = useState([]);
//   const [housesToRender, setHouseToRender] = useState([]);
//   const [loadingHouses, setLoadingHouses] = useState(true);
//   const [map, setMap] = useState(null)
//   const mapRef = useRef(null);

//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyDTzQON_0lZ0rTQ9Zw9xzwhYUkgF_mHZqs"
//   })

//     const onLoad = React.useCallback(function callback(map) {
//       mapRef.current = map;
//       const bounds = new window.google.maps.LatLngBounds(center);
//       map.fitBounds(bounds);
//       // console.log('bounds', bounds)

//       setMap(map);
//       onDragEnd();
//     }, []);

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   const onDragEnd = async () => {
//     setLoadingHouses(true)
//     const map = mapRef.current;
//     const bounds = await map.getBounds();
//     const ne = await bounds.getNorthEast();
//     const sw = await bounds.getSouthWest();
//     const nw = new window.google.maps.LatLng(ne.lat(), sw.lng());
//     const se = new window.google.maps.LatLng(sw.lat(), ne.lng());
//     const polygonCoords = [
//       [ne.lat(), ne.lng()],
//       [nw.lat(), nw.lng()],
//       [sw.lat(), sw.lng()],
//       [se.lat(), se.lng()],
//       [ne.lat(), ne.lng()],
//     ];
//     const polygonCoords2 = [
//       [ne.lng(), ne.lat()],
//       [nw.lng(), nw.lat()],
//       [sw.lng(), sw.lat()],
//       [se.lng(), se.lat()],
//       [ne.lng(), ne.lat()],
//     ];
//     // setTurfBoundaries(polygonCoords);
//     // setMapBoundaries(polygonCoords2);
//     // if (mapBoundaries.length !== 0) {
//       fetchHousesWithinBoundary(polygonCoords, polygonCoords2);
//     // }
//     // console.log('data', polygonCoords)
//   };

//   const fetchHousesWithinBoundary = async (turfBoundaries, mapBoundaries) => {
//     // setLoadingHouses(true)
//     const data = mapBoundaries;
//     try {
//       const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/houseswithinboundary`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//         }
//       );
//       const data2 = await apiResponse.json();
//       console.log('data from api', data2.data);
//       // setHousesFromDb(data2.data);
//       // console.log(housesFromDb);
//       if (data2.data.length !== 0) {
//         housesFilter(data2.data, turfBoundaries);
//         // setHouseToRender([]);
//       }
//       else {
//         setLoadingHouses(false)
//       }
//     } catch (error) {
//       setLoadingHouses(false)
//       console.log(error);
//     }
//   };

//   const housesFilter = (item, turfBoundaries) => {
//     const houses2 = item.map((item, index) => {
//       let pt = [item.houseCoordinates.coordinates[1],item.houseCoordinates.coordinates[0]];
//       let poly = turfBoundaries;

//       if(pointInPolygon(pt, poly)){
//         let data = item
//         return data
//       }else{
//         return []
//       }
//     }).flat(1)
//     // console.log("houses",houses2)
//     const removeUndefined = houses2.filter(( element ) => {
//       return element !== undefined ;
//     })
//     // console.log(removeUndefined)
//     setHouseToRender(removeUndefined)
//     // setLoadingHouses(false)
//   }

//   useEffect(() => {

//   },[])

//   const center = useMemo(() => {
//     return { lat: coords[0], lng: coords[1] }; // replace with your desired initial center coordinates
//   }, []);

//   // if(isLoaded){
//   //   return(
//   //     <GoogleMap
//   //       mapContainerStyle={containerStyle}
//   //       center={center}
//   //       zoom={13}
//   //       onLoad={onLoad}
//   //       onUnmount={onUnmount}
//   //       onDragEnd={onDragEnd}
//   //       options={createMapOptions}
//   //     >

//   //     </GoogleMap>
//   //   )
//   // }

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={10}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//       onDragEnd={onDragEnd}
//       options={createMapOptions}
//     >
//       <></>
//     </GoogleMap>
// ) : <></>
// }

// export default React.memo(MapComponent)

import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoBox,
} from "@react-google-maps/api";

import "./MapComponent.css";
import Footer from "../../common/footer/Footer.jsx";
import Card from "../../common/card/Card";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { useNavigate } from "react-router-dom";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import {
  Box,
  Button,
  MenuItem,
  Select,
  // Slider,
  SwipeableDrawer,
} from "@mui/material";
import { BeatLoader } from "react-spinners";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ReactCardFlip from "react-card-flip";

const pointInPolygon = require("point-in-polygon");

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 80px - 48px)",
};

const createMapOptions = {
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false,
};

function MapComponent(props) {
  const mapRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const scrollMapContainerRef = useRef(null);
  const [initialHouseRender, setInitialHouseRender] = useState(10);

  const [housesToRender, setHouseToRender] = useState([]);
  const [loadingHouses, setLoadingHouses] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerPosition, setSelectedMarkerPosition] = useState(null);
  const [mapCenterIniChng, setMapCenterIniChng] = useState({ lat: 2, lng: 2 });

  const [filterMenuOpened, setFilterMenuOpened] = useState(false);
  const [searchMenuOpened, setSearchMenuOpened] = useState(false);
  const [cardFlipped, setCardFlipped] = useState(false);

  //filter state start
  const [filterPrice, setFilterPrice] = useState([0, 1000000]);
  const [filterSqft, setFilterSqft] = useState([0, 10000]);
  const [filterForSale, setFilterForSale] = useState("All");
  const [filterHouseOrLand, setFilterHouseOrLand] = useState("House");
  //filter state end

  const navigator = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDTzQON_0lZ0rTQ9Zw9xzwhYUkgF_mHZqs",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(mapL) {
    mapRef.current = mapL;
    setTimeout(onDragEnd, 500);
    // console.log('ooooooo',)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onDragEnd = async () => {
    setLoadingHouses(true);
    const map = mapRef.current;
    if (!map) {
      setLoadingHouses(false);
      return;
    } // check if map is defined
    const bounds = await map.getBounds();
    if (!bounds) return; // check if bounds is defined
    const ne = await bounds.getNorthEast();
    const sw = await bounds.getSouthWest();
    const nw = new window.google.maps.LatLng(ne.lat(), sw.lng());
    const se = new window.google.maps.LatLng(sw.lat(), ne.lng());
    const polygonCoords = [
      [ne.lat(), ne.lng()],
      [nw.lat(), nw.lng()],
      [sw.lat(), sw.lng()],
      [se.lat(), se.lng()],
      [ne.lat(), ne.lng()],
    ];
    const polygonCoords2 = [
      [ne.lng(), ne.lat()],
      [nw.lng(), nw.lat()],
      [sw.lng(), sw.lat()],
      [se.lng(), se.lat()],
      [ne.lng(), ne.lat()],
    ];
    fetchHousesWithinBoundary(polygonCoords, polygonCoords2);
  };

  const fetchHousesWithinBoundary = async (turfBoundaries, mapBoundaries) => {
    // console.log("mapBoundaries", mapBoundaries);
    const data = mapBoundaries;
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/houseswithinboundary`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const data2 = await apiResponse.json();
      // console.log('data from api', data2.data);
      if (data2.data.length !== 0) {
        setInitialHouseRender(10);
        housesFilter(data2.data, turfBoundaries);
      } else {
        setLoadingHouses(false);
      }
    } catch (error) {
      setLoadingHouses(false);
      console.log(error);
    }
  };

  const housesFilter = (item, turfBoundaries) => {
    const houses2 = item
      .map((item, index) => {
        let pt = [
          item.houseCoordinates.coordinates[1],
          item.houseCoordinates.coordinates[0],
        ];
        let poly = turfBoundaries;

        if (pointInPolygon(pt, poly)) {
          let data = item;
          return data;
        } else {
          return [];
        }
      })
      .flat(1);
    const removeUndefined = houses2.filter((element) => {
      return element !== undefined;
    });
    // console.log("removeUndefined", removeUndefined);
    setHouseToRender(removeUndefined);
    setLoadingHouses(false);
  };

  const onChildClick = (marker) => {
    setSelectedMarker(marker);
    setSelectedMarkerPosition({
      lat: marker.houseCoordinates.coordinates[1],
      lng: marker.houseCoordinates.coordinates[0],
    });
  };

  const center = useMemo(() => {
    return mapCenterIniChng; // replace with your desired initial center coordinates
  }, [mapCenterIniChng]);

  useEffect(() => {
    // console.log("center", props);
    // onDragEnd()
    setMapCenterIniChng({ lat: props.coords[0], lng: props.coords[1] });
  }, [props]);

  const handleScroll = (item) => {
    // console.log("scrolling!");

    if (item == "flipped") {
      const scrollContainer = scrollContainerRef.current;
      if (
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - 350
      ) {
        // console.log("Reached the end of scroll!");
        setInitialHouseRender(initialHouseRender + 10);
        // Perform additional actions when reaching the end of scroll
      }
    } else {
      const scrollContainer = scrollMapContainerRef.current;
      if (
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - 200
      ) {
        // console.log("Reached the end of scroll!");
        setInitialHouseRender(initialHouseRender + 10);
        // Perform additional actions when reaching the end of scroll
      }
    }
  };

  return isLoaded ? (
    <>
      <div className="search__page__topbar">
        <TopSearchBar />
        <FilterElements />
      </div>
      <ReactCardFlip isFlipped={cardFlipped}>
        <div className="container__search__page">
          <div className="map_container__div">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={13}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onDragEnd={onDragEnd}
              onBoundsChanged={onDragEnd}
              onZoomChanged={onDragEnd}
              options={createMapOptions}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <>
                {housesToRender.slice(0, 60).map((item, index) => {
                  return (
                    <Marker
                      key={item._id}
                      position={{
                        lat: item.houseCoordinates.coordinates[1],
                        lng: item.houseCoordinates.coordinates[0],
                      }}
                      onClick={() => onChildClick(item)}
                      icon={{
                        url: require("../../../assets/marker.png"),
                        scaledSize: {
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  );
                })}

                {selectedMarker && selectedMarkerPosition && (
                  <InfoBox
                    position={selectedMarkerPosition}
                    options={{ closeBoxURL: "", enableEventPropagation: true }}
                  >
                    <>
                      <img
                        src="https://www.svgrepo.com/show/509055/close.svg"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 5,
                          left: 5,
                          height: 20,
                          width: 20,
                          zIndex: 1,
                          borderRadius: 360,
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setSelectedMarker(null);
                          setSelectedMarkerPosition(null);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          height: 70,
                          width: 200,
                          flexDirection: "row",
                          borderRadius: 7,
                          overflow: "hidden",
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigator(`/houseinfo/${selectedMarker._id}`);
                        }}
                      >
                        <img
                          src={selectedMarker.houseImage[0]}
                          alt=""
                          style={{
                            width: "40%",
                          }}
                        />
                        <div
                          style={{
                            width: "60%",
                          }}
                        >
                          <p className="house__price__card">
                            $ {shortenNumber(selectedMarker.price)}
                          </p>
                          <p className="house__details__card">
                            {selectedMarker.country}
                          </p>
                          <p className="house__details__card">
                            {selectedMarker.homeType}{" "}
                            {selectedMarker.forSale ? "for sale" : "for rent"}
                          </p>
                        </div>
                      </div>
                    </>
                  </InfoBox>
                )}
              </>
            </GoogleMap>
            <div className="results__count">
              {loadingHouses ? (
                <BeatLoader size={10} color="black" />
              ) : (
                <>
                  {housesToRender.length > 60 ? (
                    <span>
                      <b>60</b> of <b>{housesToRender.length}</b> shown
                    </span>
                  ) : (
                    <span>
                      <b>{housesToRender.length}</b> results
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            onScroll={() => handleScroll("notFlipped")}
            ref={scrollMapContainerRef}
            className="second__h__section"
          >
            <div className="property__results__section">
              {housesToRender
                .slice(0, initialHouseRender)
                .map((item, index) => {
                  return <Card item={item} />;
                })}
            </div>
            <Footer />
          </div>
        </div>
        <div className="container__search__page">
          <div className="results__count">
            {loadingHouses ? (
              <BeatLoader size={10} color="black" />
            ) : (
              <>
                <span>
                  <b>{housesToRender.length}</b> results
                </span>
              </>
            )}
          </div>
          <div
            ref={scrollContainerRef}
            onScroll={() => handleScroll("flipped")}
            className="property__results__section__list"
          >
            {housesToRender.slice(0, initialHouseRender).map((item, index) => {
              return <Card item={item} />;
            })}
            <Footer />
          </div>
        </div>
      </ReactCardFlip>
    </>
  ) : (
    <></>
  );

  function TopSearchBar() {
    const [address, setAddress] = useState("");

    const handleSelect = async (value) => {
      setAddress(value);
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      // console.log("latLng", latLng);
      setMapCenterIniChng(latLng);
      setSearchMenuOpened(false);
      setInitialHouseRender(10);
      const newPos = new window.google.maps.LatLng(latLng.lat, latLng.lng);
      // mapRef.current.panTo(newPos);
    };

    return (
      <div className="topBar__container">
        <div className="places__search_container">
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Enter a country, city or neighbourhood...",
                    className: "searchpage__input__box",
                    spellCheck: false,
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="place__option">loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "silver", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span className="place__option">
                          <img
                            src="https://www.svgrepo.com/show/507419/search.svg"
                            alt=""
                            style={{
                              height: 20,
                              width: 20,
                              alignSelf: "center",
                              marginRight: 10,
                            }}
                          />{" "}
                          {suggestion.description}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div className="topBar__action__container">
          <div
            onClick={() => {
              setCardFlipped(!cardFlipped);
              setInitialHouseRender(10);
            }}
            className="topBar__action"
          >
            <FormatListBulletedIcon />
            List
          </div>
          <div
            onClick={() => setSearchMenuOpened(!searchMenuOpened)}
            className="topBar__action"
          >
            <SearchIcon />
            Search
          </div>
          <div
            onClick={() => setFilterMenuOpened(!filterMenuOpened)}
            className="topBar__action"
          >
            <SortIcon />
            Filters
          </div>

          {filterMenuOpened && (
            <div className="filter__menu__smallscreen">
              {/* <Slider
                range
                value={filterPrice}
                allowCross={false}
                min={0}
                max={1000000}
                // onChange={(value) => {
                //   setFilterPrice(value);
                // }}
              /> */}

              <div className="price-filter-container">
                <label htmlFor="minPrice">
                  Min Price: <b>${Number(filterPrice[0]).toLocaleString()}</b>
                </label>
                <input
                  type="number"
                  id="minPrice"
                  value={filterPrice[0]}
                  onChange={(e) => {
                    setFilterPrice([e.target.value, filterPrice[1]]);
                  }}
                  className="price-input"
                />
                {/* <br /> */}
                <label htmlFor="maxPrice">
                  Max Price: <b>${Number(filterPrice[1]).toLocaleString()}</b>
                </label>
                <input
                  type="number"
                  id="maxPrice"
                  value={filterPrice[1]}
                  onChange={(e) => {
                    setFilterPrice([filterPrice[0], e.target.value]);
                  }}
                  className="price-input"
                />
              </div>
              <button
                className="filter__button"
                onClick={() => setFilterMenuOpened(!filterMenuOpened)}
              >
                Show <b>{housesToRender.length}</b> results
              </button>
            </div>
          )}

          {searchMenuOpened && (
            <div className="filter__menu__smallscreen">
              <div style={{ display: "flex", width: "100vw" }}>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder:
                            "Enter a country, city or neighbourhood...",
                          // className: "searchpage__input__box",
                          spellCheck: false,
                        })}
                        style={{
                          display: "flex",
                          width: "100vw",
                          height: "46px",
                          // borderBottom: "1px solid black",
                          border: "none",
                          outline: "none",
                          padding: "10px",
                          fontSize: "large",
                          fontFamily: "inherit",
                        }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && (
                          <div className="place__option">loading...</div>
                        )}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "silver", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span className="place__option">
                                <img
                                  src="https://www.svgrepo.com/show/507419/search.svg"
                                  alt=""
                                  style={{
                                    height: 20,
                                    width: 20,
                                    alignSelf: "center",
                                    marginRight: 10,
                                  }}
                                />{" "}
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <button
                className="filter__button"
                onClick={() => setSearchMenuOpened(!searchMenuOpened)}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  function FilterElements() {
    return (
      <div className="filter__elements__container">
        <Box sx={{ margin: "0 5px 0 15px", height: "90%" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterForSale}
            onChange={(value) => {
              setFilterForSale(value.target.value);
              // console.log("value.target.value", value.target.value);
            }}
            label="For sale or rent"
            sx={{ height: "100%" }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"For sale"}>For sale</MenuItem>
            <MenuItem value={"For rent"}>For rent</MenuItem>
          </Select>
        </Box>
        <Box sx={{ margin: "0 5px 0 15px", height: "90%" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={"Price"}
            defaultValue="Price"
            // onChange={(value) => {
            //   setFilterForSale(value.target.value);
            //   // console.log("value.target.value", value.target.value);
            // }}
            label="For sale or rent"
            sx={{ height: "100%" }}
          >
            <MenuItem disabled value={"Price"}>
              Price
            </MenuItem>
            <Box
              sx={{
                display: "flex",
                minWidth: "200px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={filterPrice}
                min={0}
                max={1000000}
                onChange={(value) => {
                  setFilterPrice(value.target.value);
                }}
                valueLabelDisplay="auto"
                step={1000}
                getAriaValueText={(value) => {
                  return `$${Number(value).toLocaleString()}`;
                }}
                sx={{ width: "180px" }}
              />
            </Box>
          </Select>
        </Box>
        <div className="all__filter__botton">
          <p>All filters</p>
          <img
            src="https://www.svgrepo.com/show/509905/dropdown-arrow.svg"
            alt=""
          />
        </div>
        {/* <div className="filter__botton">
          <p>Price</p>
          <img
            src="https://www.svgrepo.com/show/509905/dropdown-arrow.svg"
            alt=""
          />
        </div> */}
      </div>
    );
  }
}

function shortenNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "m";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return num.toString();
  }
}

export default React.memo(MapComponent);
