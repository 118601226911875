import React from "react";
import Footer from "../footer/Footer";
// import './CurrencyDisclaimer.css'

const CurrencyDisclaimer = () => {
  return (
    <>
      <div
        className="container"
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 80px)",
          display: "flex",
          marginTop: "10vh",
        }}
      >
        <div className="content__container">
          <h1>Currency conversion disclaimer for Asasefie</h1>
          <h4>
            Please note that the currency conversion rates provided on our app
            are estimates only and may not reflect the actual exchange rates
            used by financial institutions. The actual rate applied to your
            transaction may be different, depending on various factors such as
            fees and exchange rate fluctuations. We are not responsible for any
            discrepancies or losses that may occur as a result of using the
            currency conversion rates provided on our app. Please also note that
            the dollar amounts listed on our app are the real prices posted by
            users and are not estimates.
          </h4>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CurrencyDisclaimer;
