// import React, { useEffect, useState } from 'react'
// import './HouseDetails.css'

// import { useParams } from 'react-router-dom'
// import { BeatLoader } from 'react-spinners'

// function HouseDetails() {
//     const [loading, setLoading] = useState(false)
//     const [houseData, setHouseData] = useState(null)
//     let { id } = useParams();

//     useEffect(() => {
//       fetchHouseDetails()
//     }, [])

//     const fetchHouseDetails = async () => {
//       setLoading(true)
//         try{
//           const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/findhouse`, {
//               method: 'POST',
//               headers: {
//               'Content-Type': 'application/json'
//               },
//               body: JSON.stringify({
//                 house_id: id
//               })
//           })
//           const data2 = await apiResponse.json()

//           const house = data2.data[0].houses.find(item => item._id === id);
//           setHouseData(house);
          
//           setLoading(false)
//         } catch(error) {
//           setLoading(false)
//           console.log(error)
//         }
//     }

//   return (
//     <>
//       {loading ? (
//         <div className="house__details__loader">
//           <BeatLoader size={20} />
//         </div>
//       ):(
//         <div className="background__container__house">

//           <div className="house__details__container">

//             <div className="image__section__div">
//               <img src={houseData.houseImage[0]} alt="" className='first__image'/>
//             </div>

//             <div className="details__section__div">

//             </div>

//           </div>

//         </div>
//       )}
//     </>

//   )
// }

// export default HouseDetails


import React, { useEffect, useState, useRef } from 'react';
import './HouseDetails.css';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Footer from '../common/footer/Footer';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const createMapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false,
  mapTypeId: "hybrid",
}

const containerStyle = {
  width: '100%',
  height: '300px'
};

function HouseDetails() {
  const [loading, setLoading] = useState(false);
  const [houseData, setHouseData] = useState(null);
  const { id } = useParams();
  const imgRef = useRef(null);

  const [activeTab, setActiveTab] = useState('Overview');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDTzQON_0lZ0rTQ9Zw9xzwhYUkgF_mHZqs"
  })


  useEffect(() => {
    fetchHouseDetails();
  }, [id]);

  const fetchHouseDetails = async () => {
    setLoading(true);
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/findhouse`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            house_id: id,
          }),
        }
      );
      const data2 = await apiResponse.json();
      const house = data2.data[0].houses.find(item => item._id === id);
      setHouseData(house);
      // console.log('houseData.houseCoordinates.coordinates', house.houseCoordinates.coordinates);
      // console.log('hosue', house)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}${ampm}`;
    return `${monthNames[monthIndex]} ${day.toString().padStart(2, '0')}, ${year} at ${formattedTime}`;
  }

  const handleButtonClick = (direction) => {
    if(direction == 'right'){
        const scrollPosition = imgRef.current.scrollLeft + 600; // specify the amount you want to scroll
        imgRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });

    }else{
        const scrollPosition = imgRef.current.scrollLeft - 600; // specify the amount you want to scroll
        imgRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
    }
  };


  return (
    // <>
    //   {loading && !houseData ? (
    //     <div className="house__details__loader">
    //       <BeatLoader size={20} />
    //     </div>
    //   ) : (
    //     <div className="background__container__house">
    //       <div className="house__details__container">
    //         <div className="image__section__div">
    //           <img src={houseData?.houseImage[0]} alt="" className="first__image" />
    //           <div className='rest__image__list'>
    //             {houseData?.houseImage.slice(1,houseData?.houseImage.length+1).map((item, index) => {
    //               return(
    //                 <img src={item} alt="" className='individ__house__img' />
    //               )
    //             })}
    //           </div>
    //         </div>

    //         <div className="image__section__div__small">
    //           {houseData?.houseImage.map((item, index) => {
    //             return(
    //               <img className='imgae__smallscreen' src={item} alt="" />
    //             )
    //           })}
    //         </div>

    //         <div className="details__section__div">

    //           <div className="house__actions">
    //             <div className='action_icons'>
    //               <p>Like</p>
    //               <FavoriteBorderOutlinedIcon/>
    //             </div>
    //             <div className='action_icons'>
    //               <p>Share</p>
    //               <ShareOutlinedIcon/>
    //             </div>
    //           </div>

    //           {houseData? (
    //             <>
    //               <div className="section__one">

    //                 <span className='house__page__price'>
    //                   $ {Number(houseData.price).toLocaleString()}
    //                 </span>
    //                 <h1 className='house__page__location'>{houseData.country}</h1>
    //                 <h1 className='house__page__forsale'>
    //                   {houseData.forSale? 'For sale' : 'For rent'}
    //                 </h1>

    //                 <div className="two__buttons">
    //                   <button 
    //                     className='contact__agent__button'
    //                     onClick={() => {
    //                       window.location.href = `tel:${houseData.contactInformation}`
    //                     }}
    //                   >
    //                     Contact agent
    //                   </button>
    //                   <button className='virtual__tour__button'>
    //                     Virtual tour
    //                   </button>
    //                 </div>
    //               </div>


    //               <div className="section__two">
    //                 <TopTabBar/>
    //                 {activeTab === 'Overview' && (
    //                   <div className="tabContent">
    //                     <div className='preview'>
    //                       <p><i><ApartmentIcon/></i> {houseData.homeType}</p>
    //                       <p><i><HeatPumpIcon/></i> {houseData.heatingType}</p>
    //                       <p><i><AcUnitIcon/> </i> {houseData.coolingType}</p>
    //                     </div>
    //                     <div className="overview">
    //                       <h2>Overview</h2>
    //                       <p>{houseData.description}</p>
    //                       <p>Posted on: <b>{formatDate(houseData.createdAt)}</b></p>
    //                       <p>Asasefie last checked: <b>Pending</b></p>
    //                     </div>
    //                     <div className="house__details__map">
    //                       {isLoaded ? (
    //                         <GoogleMap
    //                           mapContainerStyle={containerStyle}
    //                           center={{
    //                             lat: houseData.houseCoordinates.coordinates[1],
    //                             lng: houseData.houseCoordinates.coordinates[0]
    //                           }}
    //                           mapTypeId='satellite'
    //                           zoom={17}
    //                           options={createMapOptions}
    //                         >
    //                           <Marker
    //                             position={{
    //                               lat: houseData.houseCoordinates.coordinates[1],
    //                               lng: houseData.houseCoordinates.coordinates[0]
    //                             }}
    //                             icon={{
    //                               url: require('../../assets/marker.png'),
    //                               scaledSize: {
    //                                 width: 50,
    //                                 height: 50
    //                               }
    //                             }}
    //                           />
    //                         </GoogleMap>
    //                       ) : (null)}
    //                     </div>
    //                   </div>
    //                 )}

    //                 {activeTab === 'Facts and Features' && (
    //                   <div className="tabContent">
                        
    //                   </div>
    //                 )}
    //               </div>

    //               <Footer/>
    //             </>
    //           ) : (null)}



    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </>



    <>
      {loading && !houseData ? (
        <div className="house__details__loader">
          <BeatLoader size={20} />
        </div>
      ) : (
        <div className='houseinfo__background'>
          {houseData? (
            <div className="house__page__container">
              <ImageSection/>
              <HouseActions/>
              <SectionOne/>
              <SectionTwo/>
              <SectionThree/>
              <Footer/>
            </div>
          ):(null)}
        </div>
      )}
    </>
  );



  function ImageSection (){
    return(
      <div style={{ position: 'relative' }}>
        <button className='img__scroll__buttons1' onClick={() => handleButtonClick('left')}>{'<'}</button>
        <button className='img__scroll__buttons2' onClick={() => handleButtonClick('right')}>{'>'}</button>
        <div ref={imgRef} className="images__container">
          {houseData.houseImage.map((image, index) => (
            <div key={index} className="tile">
              <img src={image} alt="gallery" />
            </div>
          ))}
        </div>
      </div>
    )
  }

  function HouseActions (){
    return(
      <div className="house__actions">
        <div className='action_icons'>
          <p>Like</p>
          <FavoriteBorderOutlinedIcon/>
        </div>
        <div className='action_icons'>
          <p>Share</p>
          <ShareOutlinedIcon/>
        </div>
      </div>
    )
  }

  function SectionOne (){
    return(
      <div className="section__one">
        <span className='house__page__price'>
          $ {Number(houseData.price).toLocaleString()}
        </span>
        <h1 className='house__page__location'>{houseData.country}</h1>
        <h1 className='house__page__forsale'>
          {houseData.forSale? 'For sale' : 'For rent'}
        </h1>

        <div className="two__buttons">
          <button 
            className='contact__agent__button'
            onClick={() => {
              window.location.href = `tel:${houseData.contactInformation}`
            }}
          >
            Contact agent
          </button>
          <button className='virtual__tour__button'>
            Virtual tour
          </button>
        </div>
      </div>
    )
  }

  function SectionTwo () {
    return(
      <div className="section__two">
        <TopTabBar/>
        {activeTab === 'Overview' && (
          <div className="tabContent">
            <div className='preview'>
              <p><i><ApartmentIcon/></i> {houseData.homeType}</p>
              <p><i><HeatPumpIcon/></i> {houseData.heatingType}</p>
              <p><i><AcUnitIcon/> </i> {houseData.coolingType}</p>
            </div>
            <div className="overview">
              <h2>Overview</h2>
              <p>{houseData.description}</p>
              <p>Posted on: <b>{formatDate(houseData.createdAt)}</b></p>
              <p>Asasefie last checked: <b>Pending</b></p>
            </div>
          </div>
        )}

        {activeTab === 'Facts and Features' && (
          <div className="tabContent">
          </div>
        )}
      </div>
    )
  }


  function SectionThree() {
    return(
      <div className="house__details__map">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: houseData.houseCoordinates.coordinates[1],
              lng: houseData.houseCoordinates.coordinates[0]
            }}
            zoom={17}
            options={createMapOptions}
          >
            <>
              <Marker
                position={{
                  lat: houseData.houseCoordinates.coordinates[1],
                  lng: houseData.houseCoordinates.coordinates[0]
                }}
                icon={{
                  url: require('../../assets/marker.png'),
                  scaledSize: {
                    width: 50,
                    height: 50
                  }
                }}
              />
            </>
          </GoogleMap>
        ) : (null)}
      </div>
    )
  }



  function TopTabBar() {
  
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    }
  
    return (
      <div className="TopTabBar">
        <div className={`tab ${activeTab === 'Overview' ? 'active' : ''}`} onClick={() => handleTabClick('Overview')}>
          Overview
        </div>
        <div className={`tab ${activeTab === 'Facts and Features' ? 'active' : ''}`} onClick={() => handleTabClick('Facts and Features')}>
          Facts and Features
        </div>
      </div>
    );
  }


}

export default HouseDetails;
