// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
// import { useNavigate } from 'react-router-dom';

// const pages = ['Buy', 'Rent', 'Advertise', 'About us'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// function ResponsiveAppBar() {
//   const [loggedIn, setLoggedIn] = React.useState(false)
//   const navigation = useNavigate()
//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//     // navigation('/auth')
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   return (
//     <AppBar color='default' position="fixed" style={{ height: '10vh', justifyContent: 'center', backgroundColor: 'white' }}>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: 'none', md: 'flex' },
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'inherit',
//               textDecoration: 'none',
//               width: 129
//             }}
//           >
//             ASASEFIE
//           </Typography>

//           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="black"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: 'block', md: 'none' },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center">{page}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//           {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
//           <Typography
//             variant="h5"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 7,
//               display: { xs: 'flex', md: 'none' },
//               flexGrow: 1,
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'inherit',
//               textDecoration: 'none',
//             }}
//           >
//             ASASEFIE
//           </Typography>
//           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
//             {pages.map((page) => (
//               <Button
//                 key={page}
//                 onClick={handleCloseNavMenu}
//                 sx={{ my: 2, color: 'black', display: 'block' }}
//               >
//                 {page}
//               </Button>
//             ))}
//           </Box>

//           <Box sx={{ flexGrow: 0 }}>
//             <Tooltip title="Open settings">
//               {loggedIn? (
//                 <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//                   <Avatar alt="" src="/static/images/avatar/2.jpg" />
//                 </IconButton>
//               ) : (
//                 <Typography sx={{ marginTop: 5, }}>
//                   <a href="/auth" style={{ color: 'black', textDecoration: 'none', }}>
//                     <p>Sign in</p>
//                   </a>
//                 </Typography>
//               )}
//             </Tooltip>
//             <Menu
//               sx={{ mt: '45px' }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem key={setting} onClick={handleCloseUserMenu}>
//                   <Typography textAlign="center">{setting}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }
// export default ResponsiveAppBar;

import React, { useState, useEffect } from "react";
import "./Header.css";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Link, useNavigate } from "react-router-dom";
import {
  Add,
  AppRegistration,
  Key,
  Login,
  Money,
  Search,
  Sell,
} from "@mui/icons-material";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        // console.log(user.email)
        setUserEmail(user.email);
      } else {
        setLoggedIn(false);
      }
    });
  }, []);

  const navToSearchPage = () => {
    setIsOpen(false);
    navigate("/search", {
      state: {
        coords: {
          lat: 5.6376136,
          lng: -0.1266064,
        },
      },
    });
  };

  return (
    <>
      <nav className="nav__container">
        <div className="left__section">
          <p>
            <a onClick={navToSearchPage} className="auth__nav">
              Buy
            </a>
          </p>
          <p>
            <a onClick={navToSearchPage} className="auth__nav">
              Rent
            </a>
          </p>
          <p>
            <a href="/profile" className="auth__nav">
              Sell
            </a>
          </p>
          <p>Agent finder</p>

          <img
            onClick={handleButtonClick}
            src="https://www.svgrepo.com/show/506246/menu-hamburger.svg"
            alt=""
          />
        </div>

        <div className="middle__section">
          <a href="/" className="title__text">
            <img
              src={require("../../../assets/logo.png")}
              alt=""
              className="logo"
            />
          </a>
        </div>

        <div className="right__section">
          <p>Advertise</p>
          <p>Help</p>

          {loggedIn ? (
            <a href="/profile" className="profile__avatar">
              <AccountCircleIcon fontSize="large" />
            </a>
          ) : (
            <p>
              <a href="/auth" className="auth__nav">
                Sign in
              </a>
            </p>
            // <p>
            //   <a href="/auth" className='auth__nav'>Login</a>
            // </p>
          )}
        </div>
      </nav>

      <div className={`menu ${isOpen ? "open" : "close"}`}>
        <div className="nav__container__menu">
          <div className="left__section">
            <img
              onClick={handleButtonClick}
              src="https://www.svgrepo.com/show/500510/close.svg"
              alt=""
            />
          </div>

          <div className="middle__section">
            <a href="/" className="title__text">
              <img
                src={require("../../../assets/logo.png")}
                alt=""
                className="logo"
              />
            </a>
          </div>

          <div></div>
        </div>

        <ul>
          <a onClick={navToSearchPage} className="auth__nav">
            <li>
              <Key fontSize="large" className="menu__icon" />
              Buy
            </li>
          </a>
          <a onClick={navToSearchPage} className="auth__nav">
            <li>
              <Key fontSize="large" className="menu__icon" />
              Rent
            </li>
          </a>
          <a
            onClick={() => setIsOpen(false)}
            href="/profile"
            className="auth__nav"
          >
            <li>
              <Sell fontSize="large" className="menu__icon" />
              Sell
            </li>
          </a>
          {/* <a href="/profile" className="auth__nav">
            <li>
              <Search fontSize="large" className="menu__icon" />
              Agent finder
            </li>
          </a>
          <a href="/profile" className="auth__nav">
            <li>
              <Add fontSize="large" className="menu__icon" />
              Advertise
            </li>
          </a> */}
          {!loggedIn ? (
            <>
              {/* <a
                onClick={() => setIsOpen(false)}
                href="/auth"
                className="auth__nav"
              >
                <li>
                  <AppRegistration fontSize="large" className="menu__icon" />
                  Register
                </li>
              </a> */}
              <a
                onClick={() => setIsOpen(false)}
                href="/auth"
                className="auth__nav"
              >
                <li>
                  <Login fontSize="large" className="menu__icon" />
                  Login
                </li>
              </a>
            </>
          ) : null}
        </ul>
      </div>
    </>
  );
}

export default Header;
