import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import "./UserProfile.css";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Close, Favorite, House, Key, Settings } from "@mui/icons-material";
import Footer from "../common/footer/Footer";
import Card from "../common/card/Card";
import { BeatLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "350px",
  marginTop: "10px",
};

const center = {
  lat: 51.5074,
  lng: 0.1278,
};

function UserProfile() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  //states for showing my list views for each item
  const [showMyList, setShowMyList] = useState(false);
  const [likedHouses, setLikedHouses] = useState([]);
  const [likedHousesData, setLikedHouseData] = useState([]);
  const [likedHouseLoading, setLikedHouseLoading] = useState(true);

  //states for showing settings views for each item
  const [showSettings, setShowSettings] = useState(false);

  //states for showing post your property views for each item
  const [showPostYourProperty, setShowYourProperty] = useState(false);

  //states for showing manage properties views for each item
  const [showManageProperties, setShowManageProperties] = useState(false);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const userLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const getLikedHousesFB = async () => {
    setLikedHouseData([]);
    setLikedHouseLoading(true);
    if (firebase.auth().currentUser.uid != null) {
      firebase
        .firestore()
        .collection("likedHouses")
        .doc(firebase.auth().currentUser.uid)
        .collection("houses")
        .get()
        .then(async (snapshot) => {
          let houses = snapshot.docs.map((doc) => {
            const data = doc.data();
            const id = doc.id;
            return data.id;
          });
          // console.log("houses.flat(1)", houses.flat(1));
          await setLikedHouses(houses.flat(1));
          if (houses.flat(1).length > 0) {
            for (let index = 0; index < houses.flat(1).length; index++) {
              likedHousesFromDB(index);
            }
          } else {
            setLikedHouseLoading(false);
          }
        });
    }
  };

  const likedHousesFromDB = async (index) => {
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/findhouse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            house_id: likedHouses[index],
          }),
        }
      );
      const data2 = await apiResponse.json();
      // console.log("filter", data2.data);
      if (data2.data[0] !== undefined) {
        const filter = data2.data[0].houses.filter((item) => {
          return item._id == likedHouses[index];
        });
        // console.log("filter", filter);
        setLikedHouseData(...likedHousesData, filter[0]);
      }
      setLikedHouseLoading(false);
      // console.log("liked houses from mongo", this.state.likedHousesData)
    } catch (error) {
      console.log(error);
      setLikedHouseLoading(false);
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        // console.log(user.email)
        setUserEmail(user.email);
      } else {
        setLoggedIn(false);
      }
    });
  }, []);

  return (
    <>
      <div className="userprofile__background">
        <div className="userprofile__container">
          <div className="top__section">
            <h1 className="email__tag">{userEmail}</h1>
            <div className="logout__btn__profile" onClick={userLogout}>
              Log out
            </div>
          </div>
          <div className="items__section">
            {itemsArray.map((item, index) => {
              return <ListItems item={item} key={index} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
      {showMyList ? <ShowMyListView /> : null}
      {showPostYourProperty ? <PostYourProperty /> : null}
    </>
  );

  function ListItems({ item, key }) {
    return (
      <div
        onClick={() => listItemClicked(item.name)}
        key={key}
        className="listitems__container"
      >
        <div className="action__icon">{item.icon}</div>
        <h2 className="title">{item.name}</h2>
        <span className="desc">{item.desc}</span>
      </div>
    );
  }

  function listItemClicked(item) {
    if (item == "My list") {
      setShowMyList(true);
      getLikedHousesFB();
    } else if (item == "Post your property") {
      setShowYourProperty(true);
    }
  }

  function ShowMyListView() {
    return (
      <div className="mylist__background">
        <div className="mylist__container">
          <div className="mylist__header">
            <h2>My list</h2>
            <div onClick={() => setShowMyList(false)} className="header__icon">
              <Close />
            </div>
          </div>
          <div className="mylist__content">
            {likedHousesData.length == 0 && likedHouseLoading ? (
              <div className="mylist__display">
                <BeatLoader />
              </div>
            ) : (
              <div className="mylist__display">
                {likedHousesData.length == 0 ? (
                  <span>Empty</span>
                ) : (
                  <>
                    {likedHousesData.map((item, index) => {
                      return <Card item={item} />;
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function PostYourProperty({
    steps = postYourPropertyStepper,
    initialStep = 0,
  }) {
    const [activeStep, setActiveStep] = useState(initialStep);
    const [markerPosition, setMarkerPosition] = useState(center);

    //body of api request start
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState("");
    const [propertyType, setPropertyTypeArr] = useState("Single-family-home");
    const [forSale, setForSale] = useState("For sale");
    const [houseOrLand, setHouseOrLand] = useState("House");
    const [description, setDescription] = useState("");
    const [numberOfRooms, setNumberOfRooms] = useState("");
    const [numberOfUnits, setNumberOfUnits] = useState("");
    const [numberOfStories, setNumberOfStories] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [floorCovering, setFloorCovering] = useState([]);
    const [appliances, setAppliances] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [heatingType, setHeatingType] = useState([]);

    //body of api request end

    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyDTzQON_0lZ0rTQ9Zw9xzwhYUkgF_mHZqs",
    });

    const handleNext = () => {
      setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    const onDrag = (map) => {
      // console.log(map);
      // console.log(mapRef.current.getCenter().lat());
      setMarkerPosition({
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng(),
      });
    };

    const onLoad = React.useCallback(function callback(mapL) {
      mapRef.current = mapL;
    }, []);

    const addElementToFloorCovering = (element) => {
      if (floorCovering.includes(element)) {
        setFloorCovering(floorCovering.filter((item) => item !== element));
      } else {
        setFloorCovering([...floorCovering, element]);
      }
    };

    const addElementToAppliances = (element) => {
      if (appliances.includes(element)) {
        setAppliances(appliances.filter((item) => item !== element));
      } else {
        setAppliances([...appliances, element]);
      }
    };

    const addElementToRooms = (element) => {
      if (rooms.includes(element)) {
        setRooms(rooms.filter((item) => item !== element));
      } else {
        setRooms([...rooms, element]);
      }
    };
    const addElementToHeatingType = (element) => {
      if (heatingType.includes(element)) {
        setHeatingType(heatingType.filter((item) => item !== element));
      } else {
        setHeatingType([...heatingType, element]);
      }
    };

    const LocationDetails = useMemo(() => {
      return (
        <div className="location__details__container">
          <>
            <TextField
              margin="dense"
              required
              fullWidth
              name="County"
              label="County"
              type="text"
              id="country"
              color="primary"
              sx={{ color: "white", borderColor: "black" }}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="City or town"
              label="City or town"
              type="text"
              id="Cityortown"
              color="primary"
              sx={{ color: "white", borderColor: "black" }}
            />
          </>
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <span>Select location by draging the map</span>
              <span>
                Latitude: {markerPosition.lat}, Longitude: {markerPosition.lng}
              </span>
            </div>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={17}
                onLoad={onLoad}
                onDrag={onDrag}
              >
                <Marker
                  position={markerPosition}
                  icon={{
                    url: require("../../assets/marker.png"),
                    scaledSize: {
                      width: 50,
                      height: 50,
                    },
                  }}
                />
              </GoogleMap>
            ) : (
              <div>Loading Map...</div>
            )}
          </>
        </div>
      );
    }, [markerPosition, isLoaded]);

    const ImageDetails = useMemo(() => {
      const handleFileInputChange = (event) => {
        const files = event.target.files;
        const fileNames = Object.keys(files).map((key) => files[key]);
        // console.log("fileNames", fileNames);
        setImages(fileNames);
      };
      return (
        <>
          {/* {images.length == 0 ? ( */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <label htmlFor="fileInput" className="uploadButton">
              {images.length > 0 ? "Change Images" : "Select Images"}
            </label>
            <input
              type="file"
              multiple={true}
              id="fileInput"
              accept=".jpg,.png"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="addproperty__image__list">
            {images.length > 0 && (
              <>
                {images.map((item, index) => {
                  return (
                    <img
                      src={URL.createObjectURL(item)}
                      key={index}
                      alt="Selected"
                      className="indi__img__list"
                    />
                  );
                })}
              </>
            )}
          </div>
          {/* ) : (
            <div></div>
          )} */}
        </>
      );
    }, [images]);

    const HouseDeatils = useMemo(() => {
      return (
        <div>
          <div className="selector__div">
            <span>For sale or rent</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={forSale}
              onChange={(value) => {
                setForSale(value.target.value);
                // console.log("value.target.value", value.target.value);
              }}
              label="For sale or rent"
            >
              <MenuItem value={"For sale"}>For sale</MenuItem>
              <MenuItem value={"For rent"}>For rent</MenuItem>
            </Select>
          </div>
          <div className="selector__div">
            <span>House or land</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={houseOrLand}
              onChange={(value) => {
                setHouseOrLand(value.target.value);
                // console.log("value.target.value", value.target.value);
              }}
              label="House or land"
            >
              <MenuItem value={"House"}>House</MenuItem>
              <MenuItem value={"Land"}>Land</MenuItem>
            </Select>
          </div>
          <TextField
            margin="dense"
            required
            fullWidth
            name="Price ($)"
            label="Price ($)"
            type="text"
            id="price"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => setPrice(value.target.value)}
            value={price}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="Description"
            label="Description"
            type="text"
            id="description"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => setDescription(value.target.value)}
            value={description}
            multiline
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="Total number of rooms"
            label="Total number of rooms"
            type="number"
            id="rooms"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => setNumberOfRooms(value.target.value)}
            value={numberOfRooms}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="Number of units"
            label="Number of units"
            type="text"
            id="units"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => setNumberOfUnits(value.target.value)}
            value={numberOfUnits}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="Number of stories"
            label="Number of stories"
            type="text"
            id="stories"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => setNumberOfStories(value.target.value)}
            value={numberOfStories}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="Contact number"
            label="Contact number"
            type="number"
            id="number"
            color="primary"
            sx={{ color: "white", borderColor: "black" }}
            onChange={(value) => {
              setContactNumber(value.target.value);
              // console.log("value.target.value", value.target.value);
            }}
            onch
            value={contactNumber}
          />
          <div className="selector__div">
            <span>Property type</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={propertyType}
              onChange={(value) => {
                setPropertyTypeArr(value.target.value);
                // console.log("value.target.value", value.target.value);
              }}
              label="Property type"
            >
              {propertyTypeArr.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <div className="checkgroup__container">
            <div className="checkgroup__item">
              <FormGroup>
                <span>Floor covering</span>
                {floorCoveringItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color="default"
                        onChange={(value) => {
                          addElementToFloorCovering(value.target.value);
                        }}
                        value={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="checkgroup__item">
              <FormGroup>
                <span>Floor covering</span>
                {appliancesItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color="default"
                        onChange={(value) => {
                          addElementToAppliances(value.target.value);
                        }}
                        value={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="checkgroup__item">
              <FormGroup>
                <span>Floor covering</span>
                {roomsItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color="default"
                        onChange={(value) => {
                          addElementToRooms(value.target.value);
                        }}
                        value={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="checkgroup__item">
              <FormGroup>
                <span>Floor covering</span>
                {heatingTypeItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color="default"
                        onChange={(value) => {
                          addElementToHeatingType(value.target.value);
                        }}
                        value={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      );
    }, [
      numberOfStories,
      propertyType,
      forSale,
      houseOrLand,
      contactNumber,
      numberOfUnits,
      numberOfRooms,
      price,
      description,
      floorCovering,
      appliances,
      rooms,
      heatingType,
    ]);

    const ReviewAndSubmit = () => {
      return (
        <div className="reviewandsubmit__contan">
          <div className="addproperty__image__list">
            {images.length > 0 && (
              <>
                {images.map((item, index) => {
                  return (
                    <img
                      src={URL.createObjectURL(item)}
                      key={index}
                      alt="Selected"
                      className="indi__img__list"
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className="column__items">
            <span className="column__items__span">
              Price ($): <b>${Number(price).toLocaleString()}</b>
            </span>
            <span className="column__items__span">
              For sale or rent: <b>{forSale}</b>
            </span>
            <span className="column__items__span">
              House or land: <b>{houseOrLand}</b>
            </span>
            <span className="column__items__span">
              Property type: <b>{propertyType}</b>
            </span>
            <span className="column__items__span">
              Description: <b>{description}</b>
            </span>
            <span className="column__items__span">
              Total number of rooms: <b>{numberOfRooms}</b>
            </span>
            <span className="column__items__span">
              Total number of units: <b>{numberOfUnits}</b>
            </span>
            <span className="column__items__span">
              Total number of stories: <b>{numberOfStories}</b>
            </span>
            <span className="column__items__span">
              Contact number: <b>{contactNumber}</b>
            </span>
          </div>
          <div className="array__items__containter">
            <div className="array__items">
              <span className="column__items__span">Floor covering: </span>
              {floorCovering.map((item, index) => (
                <span className="array__items__span" key={index}>
                  *{item}
                </span>
              ))}
            </div>
            <div className="array__items">
              <span className="column__items__span">Appliances: </span>
              {appliances.map((item, index) => (
                <span className="array__items__span" key={index}>
                  *{item}
                </span>
              ))}
            </div>
            <div className="array__items">
              <span className="column__items__span">Rooms: </span>
              {rooms.map((item, index) => (
                <span className="array__items__span" key={index}>
                  *{item}
                </span>
              ))}
            </div>
            <div className="array__items">
              <span className="column__items__span">Heating type: </span>
              {heatingType.map((item, index) => (
                <span className="array__items__span" key={index}>
                  *{item}
                </span>
              ))}
            </div>
          </div>
          <button className="submit__property">Submit</button>
        </div>
      );
    };

    return (
      <div className="mylist__background">
        <div className="mylist__container">
          <div className="mylist__header">
            <h2>Post your property</h2>
            <div
              onClick={() => setShowYourProperty(false)}
              className="header__icon"
            >
              <Close />
            </div>
          </div>
          <div className="postyourproperty__content">
            <div className="postproperty__step__items">
              <h1>{steps[activeStep].title}</h1>
              {steps[activeStep].id == 1 && <>{LocationDetails}</>}
              {steps[activeStep].id == 2 && <>{ImageDetails}</>}
              {steps[activeStep].id == 3 && <>{HouseDeatils}</>}
              {steps[activeStep].id == 4 && (
                <>
                  <ReviewAndSubmit />
                </>
              )}
            </div>
            <div className="control__steps">
              <div>Step {activeStep + 1}</div>
              <button
                className="stepper__btn"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className="stepper__btn"
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const postYourPropertyStepper = [
  {
    title: "Location details",
    id: 1,
  },
  {
    title: "Property images",
    id: 2,
  },
  {
    title: "Property details",
    id: 3,
  },
  {
    title: "Review and submit",
    id: 4,
  },
];

const itemsArray = [
  {
    name: "Settings",
    desc: "Change currencies and more",
    icon: <Settings fontSize="large" />,
  },
  {
    name: "My list",
    desc: "See your favorited listings",
    icon: <Favorite fontSize="large" />,
  },
  {
    name: "Post your property",
    desc: "List your propery for sale or for rent",
    icon: <House fontSize="large" />,
  },
  {
    name: "Manage properties",
    desc: "Edit or delete properties you have posted",
    icon: <Key fontSize="large" />,
  },
];

const propertyTypeArr = [
  "Single-family-home",
  "Co-op or condo",
  "Townhouse",
  "Multi-family home",
  "Modular home",
];

// const checkItems = [
//   {
//     title: "Floor covering",
//     iniItems: ["Carpet", "Concrete", "Tile", "Other"],
//   },
//   {
//     title: "Appliances",
//     iniItems: ["Dryer", "Freezer", "Dishwasher"],
//   },
//   {
//     title: "Rooms",
//     iniItems: ["Library", "Office", "Pantry"],
//   },
//   {
//     title: "Heating type",
//     iniItems: ["Stove", "Wall", "None"],
//   },
// ];

const floorCoveringItems = ["Carpet", "Concrete", "Tile", "Other"];
const appliancesItems = ["Dryer", "Freezer", "Dishwasher"];
const roomsItems = ["Library", "Office", "Pantry"];
const heatingTypeItems = ["Stove", "Wall", "None"];

export default UserProfile;
