import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "./componenet/privateRoute/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";

import Home from "./componenet/home/Home";
import Header from "./componenet/common/header/Header.jsx";
import Search from "./componenet/search/Search.jsx";
import Auth from "./componenet/common/auth/Auth";
import CurrencyDisclaimer from "./componenet/common/bits/CurrencyDisclaimer";
import ChoosePlatform from "./componenet/common/bits/ChoosePlatform";
import HouseDetails from "./componenet/houseDetails/HouseDetails";
import UserProfile from "./componenet/userProfile/UserProfile";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <PrivateRoute path="/search" element={<Search/>}/> */}

            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<UserProfile />} />
            </Route>

            <Route path="/search" element={<Search />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/houseinfo/:id" element={<HouseDetails />} />
            <Route
              path="/currencydisclaimer"
              element={<CurrencyDisclaimer />}
            />
            <Route path="/downloadplatform" element={<ChoosePlatform />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
