// import React, { useState, useEffect } from 'react'
// import './Home.css'

// import Typed from 'react-typed';
// import PlacesAutocomplete, {geocodeByAddress,getLatLng} from "react-places-autocomplete";
// import { Link, useNavigate } from 'react-router-dom';

// import { motion } from 'framer-motion';
// import Footer from '../common/footer /Footer';

// function Home() {
//     const navigate =  useNavigate()
//     const [address, setAddress] = useState("");
//     const [coordinates, setCoordinates] = useState({
//       lat: null,
//       lng: null
//     });

//     const handleSelect = async value => {
//         setAddress(value);
//         const results = await geocodeByAddress(value);
//         const latLng = await getLatLng(results[0]);
//         // console.log('latLng', latLng)
//         setCoordinates(latLng);
//         navigate("/search",{
//             state:{
//                 coords: latLng
//             }
//         })
//         // <Navigate to="/search" state={{ todos: 'name'}}/>
//     };

//   return (
//     <>
//         <div className='home__container'>
//             <div className='hero__text__div'>
//                 <Typed
//                     strings={[
//                         'Welcome to Asasefie'
//                     ]}
//                     typeSpeed={40}
//                     loop={false}
//                 />
//                 <Typed
//                     strings={[
//                         'Discover your dream home',
//                         'Discover your dream rental',
//                         // 'Enter a counrty, city or neighbourhood in the input below'
//                     ]}
//                     typeSpeed={80}
//                     backSpeed={60}
//                     loop
//                     className='hero__text'
//                 />
//             </div>

//             <div className="input__section">
//                 <div className="input__container">
//                     <PlacesAutocomplete
//                         value={address}
//                         onChange={setAddress}
//                         onSelect={handleSelect}
//                     >
//                         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//                         <div style={{ width: '100%', }}>
//                             <input
//                             {...getInputProps({
//                                 placeholder: 'Enter a country, city or neighbourhood...',
//                                 className: 'input__box',
//                                 spellCheck: false
//                             })}
//                             />
//                             <div className="autocomplete-dropdown-container">
//                             {loading &&
//                                 <div className='place__option'>
//                                     loading...
//                                 </div>
//                             }
//                             {suggestions.map(suggestion => {
//                                 const className = suggestion.active
//                                 ? 'suggestion-item--active'
//                                 : 'suggestion-item';
//                                 // inline style for demonstration purpose
//                                 const style = suggestion.active
//                                 ? { backgroundColor: 'silver', cursor: 'pointer' }
//                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
//                                 return (
//                                 <div
//                                     {...getSuggestionItemProps(suggestion, {
//                                     className,
//                                     style,
//                                     })}
//                                 >
//                                     <span className='place__option'>{suggestion.description}</span>
//                                 </div>
//                                 );
//                             })}
//                             </div>
//                         </div>
//                         )}
//                     </PlacesAutocomplete>
//                 </div>

//                 {/* <div className="search_btn">
//                     Search
//                 </div> */}
//             </div>

//             <div className="animation__container">

//                 {[{bottom: 160, speed: 5}, {bottom: 150, speed: 7}, {bottom: 140, speed: 3}].map((item, index) => {
//                     return(
//                         <motion.img
//                             key={index}
//                             src={'https://www.svgrepo.com/show/487079/bird.svg'}
//                             alt="My animated image"
//                             style={{ width: '25px', height: '25px', position: 'absolute', bottom: item.bottom }}
//                             animate={{ x: '10vw' }}
//                             transition={{ duration: item.speed, repeat: Infinity, repeatType: 'reverse' }}
//                         />
//                     )
//                 })}
//                 {[{bottom: 160, speed: 5, }, {bottom: 150, speed: 7}, {bottom: 140, speed: 3}].map((item, index) => {
//                     return(
//                         <motion.img
//                             key={index}
//                             src={'https://www.svgrepo.com/show/487079/bird.svg'}
//                             alt="My animated image"
//                             style={{ width: '25px', height: '25px', position: 'absolute', bottom: item.bottom, right: 0 }}
//                             animate={{ x: '-10vw' }}
//                             transition={{ duration: item.speed, repeat: Infinity, repeatType: 'reverse' }}
//                         />
//                     )
//                 })}

//                 <motion.img
//                     src={'https://www.svgrepo.com/show/467056/helicopter.svg'}
//                     alt="My animated image"
//                     style={{ width: '45px', height: '45px', position: 'absolute', bottom: 180, left: -50 }}
//                     animate={{ x: '110vw', }}
//                     transition={{ duration: 15, repeat: Infinity, repeatType: 'loop' }}
//                 />
//                 <motion.img
//                     src={'https://www.svgrepo.com/show/507994/cloud.svg'}
//                     alt="My animated image"
//                     style={{ width: '85px', height: '85px', position: 'absolute', bottom: 230 }}
//                     animate={{ x: '100vw', }}
//                     transition={{ duration: 20, repeat: Infinity, repeatType: 'reverse' }}
//                 />
//                 <motion.img
//                     src={'https://www.svgrepo.com/show/497884/cloud-sunny.svg'}
//                     alt="My animated image"
//                     style={{ width: '85px', height: '85px', position: 'absolute', bottom: 300 }}
//                     animate={{ x: '100vw', }}
//                     transition={{ duration: 30, repeat: Infinity, repeatType: 'reverse' }}
//                 />

//                 <div className="houses">
//                     <img src="https://www.svgrepo.com/show/124324/city-buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/175162/buildings-modern-couple.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/168884/two-buildings-with-many-windows.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/81387/offices-buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/80857/buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/106582/two-city-buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/163816/buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/145631/two-buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/127226/buildings-in-the-city-cartoon-outline.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/134944/buildings-of-modern-architectural-style.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/67736/buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/124324/city-buildings.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/72392/skyscraper-buildings-linked-to-connectors.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/134944/buildings-of-modern-architectural-style.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/129400/buildings-trees-and-plants-in-cityscape-view.svg" alt="" />
//                     <img src="https://www.svgrepo.com/show/452827/cypress.svg" alt="" />
//                 </div>
//             </div>
//         </div>
//         <Footer />
//     </>
//   )
// }

// export default Home

import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Link, useNavigate } from "react-router-dom";

import Card from "../common/card/Card";
import Footer from "../common/footer/Footer";

const pointInPolygon = require("point-in-polygon");

function Home() {
  const [housesToRender, setHouseToRender] = useState([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const listRef = useRef(null);

  const handleSelect = async (value) => {
    setAddress(value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    // console.log("latLng", latLng);
    setCoordinates(latLng);
    navigate("/search", {
      state: {
        coords: latLng,
      },
    });
  };

  const random = Math.floor(Math.random() * 3);
  const locIndex = forYouDeets[random];

  useEffect(() => {
    sendBoudsWithoutMap(locIndex.lat, locIndex.lng);
  }, []);

  const sendBoudsWithoutMap = async (plat, plgn) => {
    // setLoadingHouses(true)
    // console.log("starting", props)
    const km = 0.04492363;
    const lat = plat;
    const lgn = plgn;
    const data = [
      [lgn - km, lat - km],
      [lgn - km, lat + km],
      [lgn + km, lat + km],
      [lgn + km, lat - km],
      [lgn - km, lat - km],
    ];
    const turfBoudaries = [
      [lat - km, lgn - km],
      [lat + km, lgn - km],
      [lat + km, lgn + km],
      [lat - km, lgn + km],
      [lat - km, lgn - km],
    ];
    // await setTurfBoundaries(turfBoudaries)
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/houseswithinboundary`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const data2 = await apiResponse.json();
      // console.log('data', data2.data)
      // setHousesFromDb(data2.data)
      if (data2.data.length !== 0) {
        // setHouseToRender([])
        // setLoadingHouses(false)
        housesFilter(data2.data, turfBoudaries);
      } else {
        // setLoadingHouses(false)
        setHouseToRender([]);
      }
    } catch (error) {
      console.log(error);
      // setLoadingHouses(false)
    } finally {
    }
  };

  const housesFilter = (item, bounds) => {
    const houses2 = item
      .map((item, index) => {
        let pt = [
          item.houseCoordinates.coordinates[1],
          item.houseCoordinates.coordinates[0],
        ];
        let poly = bounds;
        // console.log('trurf', turfBoundaries)

        if (pointInPolygon(pt, poly)) {
          let data = item;
          return data;
        }
      })
      .flat(1);

    // console.log("houses",houses2)
    const removeUndefined = houses2.filter((element) => {
      return element !== undefined;
    });
    // console.log("removeUndefined", removeUndefined);
    setHouseToRender(removeUndefined);
    // setLoadingHouses(false)
  };

  const handleButtonClick = (direction) => {
    if (direction == "right") {
      const scrollPosition = listRef.current.scrollLeft + 500; // specify the amount you want to scroll
      listRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    } else {
      const scrollPosition = listRef.current.scrollLeft - 500; // specify the amount you want to scroll
      listRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  function handleCardButton(item) {
    if (item == "Buy" || item == "Rent") {
      navigate("/search", {
        state: {
          coords: {
            lat: 5.6376136,
            lng: -0.1266064,
          },
        },
      });
    } else {
      navigate("/profile");
    }
  }

  return (
    <>
      <div className="hero__container">
        <h1>Discover your dream property!</h1>

        <div className="input__section">
          <div className="input__container">
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}
                >
                  <input
                    {...getInputProps({
                      placeholder: "Enter a country, city or neighbourhood...",
                      className: "input__box",
                      spellCheck: false,
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="place__option">loading...</div>}
                    {/* {suggestions.length !== 0  || loading?(
                                <div 
                                    onClick={() => {
                                        navigator.geolocation.getCurrentPosition((position) => {
                                            navigate("/search",{
                                                state:{
                                                    coords: {
                                                        lat: position.coords.latitude,
                                                        lng: position.coords.longitude  
                                                    }
                                                }
                                            })
                                        })
                                    }}
                                >
                                    <span className='place__option'><img src="https://www.svgrepo.com/show/513450/location-pin.svg" alt="" style={{ height: 20, width: 20, alignSelf: 'center', marginRight: 10 }} /> Use current location</span>
                                </div>
                             ):(null)} */}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "silver", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span className="place__option">
                            <img
                              src="https://www.svgrepo.com/show/507419/search.svg"
                              alt=""
                              style={{
                                height: 20,
                                width: 20,
                                alignSelf: "center",
                                marginRight: 10,
                              }}
                            />{" "}
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
      </div>

      <div className="properties__for__you">
        <p
          style={{
            marginTop: 5,
            marginBottom: 0,
            fontWeight: "bold",
            letterSpacing: 1,
            fontFamily: "sans-serif",
            fontSize: 20,
          }}
        >
          Properties for you
        </p>
        <p style={{ marginTop: 5, marginBottom: 0, color: "GrayText" }}>
          {locIndex.area}
        </p>
        <div className="scroll__container">
          <button
            className="scroll__buttons"
            onClick={() => handleButtonClick("left")}
          >
            {"<"}
          </button>
          <button
            className="scroll__buttons"
            onClick={() => handleButtonClick("right")}
          >
            {">"}
          </button>
        </div>
        <ul
          ref={listRef}
          style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
        >
          {housesToRender.slice(0, 10).map((item, index) => {
            return (
              <ui>
                <div key={item._id} className="card__container">
                  <Card item={item} />
                </div>
              </ui>
            );
          })}
        </ul>
      </div>

      <div className="info__section">
        {data.map((item, index) => {
          return <HomeCard item={item} key={index} />;
        })}
      </div>
      <Footer />
    </>
  );

  function HomeCard({ item }) {
    return (
      <div className="home__card__container">
        <img src={item.url} alt="" />
        <h3>{item.title}</h3>
        <p>{item.description}</p>
        <div
          onClick={() => handleCardButton(item.title)}
          className="card__button"
        >
          <p>{item.button}</p>
        </div>
      </div>
    );
  }
}

export default Home;

const data = [
  {
    title: "Buy",
    description:
      "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/07/Buy_a_home.webp",
    button: "Browse properties",
  },
  {
    title: "Sell",
    description:
      "No matter what path you take to sell your home, we can help you navigate a successful sale.",
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/07/Sell_a_home.webp",
    button: "See options",
  },
  {
    title: "Rent",
    description:
      "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.",
    url: "https://wp-tid.zillowstatic.com/bedrock/app/uploads/sites/5/2022/08/Rent_a_home.webp",
    button: "Find properties",
  },
];

const forYouDeets = [
  {
    lat: 5.6376136,
    lng: -0.1266064,
    area: "Ghana",
  },
  {
    lat: 5.6376136,
    lng: -0.1266064,
    area: "Ghana",
  },
  {
    lat: 5.6376136,
    lng: -0.1266064,
    area: "Ghana",
  },
];
