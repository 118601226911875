import React, { useEffect } from "react";

const ChoosePlatform = () => {
  useEffect(() => {
    const platform = window.navigator.platform;
    // console.log("window.navigator.platform", platform)
    if (
      platform == "Macintosh" ||
      platform == "MacIntel" ||
      platform == "MacPPC" ||
      platform == "Mac68K" ||
      platform == "iPhone"
    ) {
      window.location.replace(
        "https://apps.apple.com/gh/app/asasefie/id1666692315"
      );
    } else {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.asasefie.realestate.app&hl=en&gl=US"
      );
    }
  }, []);

  return (
    <div className="download__img">
      {/* <h3>Get the app</h3> */}
      <a
        href="https://apps.apple.com/gh/app/asasefie/id1666692315"
        style={{
          color: "black",
          textDecoration: "none",
          width: "100vw",
          height: 60,
        }}
      >
        <img
          style={{ width: 100 }}
          className="image__tag"
          src={"https://s.zillowstatic.com/pfs/static/app-store-badge.svg"}
          alt=""
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.asasefie.realestate.app&hl=en&gl=US"
        style={{
          color: "black",
          textDecoration: "none",
          width: "100vw",
          height: 60,
        }}
      >
        <img
          style={{ width: 100 }}
          className="image__tag"
          src={"https://s.zillowstatic.com/pfs/static/google-play-badge.svg"}
          alt=""
        />
      </a>
    </div>
  );
};

export default ChoosePlatform;
