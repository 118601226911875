// import { Button } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom'
// import ListView from './list/ListView'
// import MapComponent from './map/MapComponent'
// import './Search.css'

// import MapIcon from '@mui/icons-material/Map';
// import ListIcon from '@mui/icons-material/List';
// import SearchIcon from '@mui/icons-material/Search';
// import FilterIcon from '@mui/icons-material/Sort';


// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// // import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

// import PlacesAutocomplete, {geocodeByAddress,getLatLng} from "react-places-autocomplete";


// function Search() {
//     const Location = useLocation()
//     const [listView, setListView] = useState(true)
//     const [address, setAddress] = useState("");
//     const [coords, setCoords] = useState([]);

//     const [state, setState] = React.useState({
//         bottom: false,
//     });

//     useEffect(() => {
//         console.log('props', Location.state)
//         setCoords([Location.state.coords.lat, Location.state.coords.lng])
//     }, [])

//     const handleSelect = async value => {
//         setAddress(value);
//         const results = await geocodeByAddress(value);
//         const latLng = await getLatLng(results[0]);
//         console.log('latLng', latLng)
//         setCoords([latLng.lat, latLng.lng])
//     };

//     const toggleDrawer = (anchor, open) => (event) => {
//         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//           return;
//         }
    
//         setState({ ...state, [anchor]: open });
//     };

//     const list = (anchor) => (
//         <Box
//           sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, height: '90vh' }}
//           role="presentation"
//         >
//             <div className="input__section2">
//                 <div className="input__container2">
//                     <PlacesAutocomplete
//                         value={address}
//                         onChange={setAddress}
//                         onSelect={handleSelect}
//                     >
//                         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//                         <div style={{ width: '100%', }}>
//                             <input
//                             {...getInputProps({
//                                 placeholder: 'Enter a country, city or neighbourhood...',
//                                 className: 'input__box2',
//                                 spellCheck: false
//                             })}
//                             />
//                             <div className="autocomplete-dropdown-container">
//                             {loading &&                         
//                                 <div className='place__option2'>          
//                                     loading...
//                                 </div>
//                             }
//                             {suggestions.map(suggestion => {
//                                 const className = suggestion.active
//                                 ? 'suggestion-item--active'
//                                 : 'suggestion-item';
//                                 // inline style for demonstration purpose
//                                 const style = suggestion.active
//                                 ? { backgroundColor: 'silver', cursor: 'pointer' }
//                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
//                                 return (
//                                 <div
//                                     {...getSuggestionItemProps(suggestion, {
//                                     className,
//                                     style,
//                                     })}
//                                 >
//                                     <span  onClick={toggleDrawer(anchor, false)} className='place__option2'>{suggestion.description}</span>
//                                 </div>
//                                 );
//                             })}
//                             </div>
//                         </div>
//                         )}
//                     </PlacesAutocomplete>
//                 </div>
//             </div>
//         </Box>
//     );

//   return (
//     <div className=''>

//         <div className="action">
//             <Button 
//                 // className='method__button' 
//                 color='inherit'
//                 variant="contained" 
//                 endIcon={listView?(<MapIcon/>):(<ListIcon/>)}
//                 onClick={() => {
//                     setListView(!listView)
//                 }}
//                 sx={{
//                     // position: 'absolute',
//                     // bottom: 0,
//                     // top: '85vh',
//                     // left: '10px',
//                     margin: 2,
//                     width: '30%',
//                     backgroundColor: '#b8b8b8'
//                 }}
//             >
//                 {listView? 'Map' : 'List'}
//             </Button>
//             <Button 
//                 // className='method__button' 
//                 color='inherit'
//                 variant="contained" 
//                 endIcon={<SearchIcon/>}
//                 onClick={
//                     toggleDrawer('bottom', true)
//                 }
//                 sx={{
//                     // position: 'absolute',
//                     // bottom: 0,
//                     // top: '85vh',
//                     // left: '10px',
//                     margin: 2,
//                     width: '30%',
//                     backgroundColor: '#b8b8b8'
//                 }}
//             >
//                 Search
//             </Button>
//             <Button 
//                 // className='method__button' 
//                 color='inherit'
//                 variant="contained" 
//                 endIcon={<FilterIcon/>}
//                 onClick={() => {
//                     setListView(!listView)
//                 }}
//                 sx={{
//                     // position: 'absolute',
//                     // bottom: 0,
//                     // top: '85vh',
//                     // left: '10px',
//                     margin: 2,
//                     width: '30%',
//                     backgroundColor: '#b8b8b8'
//                 }}
//             >
//                 Filter
//             </Button>
//             <div>
//       {['bottom'].map((anchor) => (
//         <React.Fragment key={anchor}>
//           {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
//           <Drawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//             sx={{
//                 height: '80vh'
//             }}
//           >
//             {list(anchor)}
//           </Drawer>
//         </React.Fragment>
//       ))}
//     </div>
//         </div>

//         <div className='search__page__container'>
//             {listView?(
//                     <ListView
//                         center={{
//                             lat: coords[0],
//                             lng: coords[1]
//                         }}
//                     />
//                 ):(
//                     // <MapComponent
//                     //     center={{
//                     //         lat: coords[0],
//                     //         lng: coords[1]
//                     //     }}
//                     // />
//                     <div style={{ display: 'flex', height: '90vh', width: '100vw', justifyContent: 'center',alignItems: 'center' }}>
//                         <h1>Page is under development</h1>
//                     </div>
//             )}
//         </div>

//         {/* <Button 
//             className='method__button' 
//             color='inherit'
//             variant="contained" 
//             endIcon={listView?(<MapIcon/>):(<ListIcon/>)}
//             onClick={() => {
//                 setListView(!listView)
//             }}
//             sx={{
//                 position: 'absolute',
//                 // bottom: 0,
//                 top: '85vh',
//                 left: '10px',
//                 backgroundColor: '#b8b8b8'
//             }}
//         >
//             {listView? 'Map' : 'List'}
//         </Button> */}
//     </div>
//   )
// }

// export default Search




import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import MapComponent from './map/MapComponent'


function Search() {
    const Location = useLocation()
    const [coords, setCoords] = useState([])

    useEffect(() => {
        console.log('props', Location.state)
        setCoords([Location.state.coords.lat, Location.state.coords.lng])
    }, [])

  return (
    <>
        <MapComponent
            coords={coords}
        />
    </>
  )
}

export default Search